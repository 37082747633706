/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Subtitle, Text, Image, SeparateLine, SeparateLineWrap, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Phony bran"} description={"Elektronické systémy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"POHON POSUVNÉ BRÁNY - BFT DEIMOS BT A600 KIT\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Kvalitní a výkonný elektrický pohon BFT Deimos BT A600, pro posuvnou bránu váhy do 600 kg, Napájení 230 V. Inovované provedení s komforním a jednoduchým digitálním nastavováním dojezdu.<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 230V AC<br>2x fotobuňka<br>1x maják<br>Dálkové ovládání<br>Možnost doplnění o ovládání mobilním telefonem</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/aa4c9218ff9d45398e9e5fbe82178d57_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/aa4c9218ff9d45398e9e5fbe82178d57_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/aa4c9218ff9d45398e9e5fbe82178d57_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/aa4c9218ff9d45398e9e5fbe82178d57_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xj2pcnpkz59"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"POHON KŘÍDLOVÉ BRÁNY BFT PHOBOS BTA40 KIT+ MAJÁK\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Souprava automatiky pro dvoukřídlou bránu s délkou křídla myx. 4 metry. Válcové servomotory pro křídlové brány o hmotnosti do 250 kg s délkou křídel až 4 metry. Jsou poháněny dvěma servomotory s napájením 24 V. Řídicí jednotka THALIA LIGHT umožňuje množství ovládacích funkcí a má integrovaný příjmač s dynamicky variabilním kódem a pamětí pro 63 dálkových ovladačů. Tyto pohony mají magnetické koncové spínače pro otevírání a zavírání. Uvolnění brány unikátním klíčem zajišťuje vysokou úroveň zabezpečení. Zvýšená bezpečnost je také zajištěna funkcemi pro detekci amperometrických překážek. BFO PHOBOS BT A40 lze provozovat s frekvencí až 80 cyklů denně, což je více než uspokojivé. Mimořádně zajímavé a inovativní řešení je také držák na místo s magnetickým kolíkem, což zlepšuje jak instalaci tak zlepšuje estetiku instalace, protože magnetický kolík není viditelný z vnějšku.<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 230V AC<br>2x pohon křídla<br>1x řídící jednotka<br>2x fotobuňka<br>1x maják<br>Dálkové ovládání<br>Možnost doplnění o ovládání mobilním telefonem</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/71b2a09cd1f1410e8acc0e17f16de0cf_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/71b2a09cd1f1410e8acc0e17f16de0cf_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/71b2a09cd1f1410e8acc0e17f16de0cf_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/71b2a09cd1f1410e8acc0e17f16de0cf_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}